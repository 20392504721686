<template>
  <div class="home-body home-4">
    <div class="wrapper">
      <header class="header-area stick-h2">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="logo floatleft">
                <a href="/">
                  <img
                    alt="PersonalPac - logo"
                    src="../assets/logo/giveProudly-log.svg"
                  />
                </a>
              </div>
              <div class="main-menu floatright">
                <nav>
                  <ul>
                    <li class="active"><a href="/">HOME</a></li>
                    <li><a href="/about" class="remove-link">ABOUT</a></li>
                    <li><a href="/faq" class="remove-link">FAQ</a></li>
                    <li><a href="/contact" class="remove-link">CONTACT</a></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
      <!-- mobile nav menu -->
      <div class="mobile-menu-area d-block d-lg-none">
        <div class="container">
          <div class="mobile-menu-area">
            <div class="row">
              <div class="col-md-12">
                <div class="mobile-menu text-center py-3">
                  <nav>
                    <a class="active remove-link mr-3" href="/">HOME </a>
                    <a class="remove-link mr-3" href="/about">ABOUT </a>
                    <a class="remove-link mr-3" href="/faq">FAQ </a>
                    <a class="remove-link" href="/contact">CONTACT </a>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="contact-area" class="map-area-two-text pt-160 pb-80">
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
              <div class="what-top">
                <div class="section-title">
                  <h1 class="main-heading mb-4">TERMS &amp; CONDITIONS</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md col-sm mb-80">
              <div id="termsText">
                <!-- this is where the terms content will be populated -->
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- footer section -->
      <footer id="footer" class="social-area">
        <div class="footer-top">
          <div class="container-lg">
            <div class="row">
              <div
                class="d-flex flex-row align-items-center justify-content-start col-lg-6 col-md-6 col-sm-6 col-12"
              >
                <h4 class="social-follow-title">Follow Us</h4>
              </div>
              <div
                class="d-flex flex-row align-items-center justify-content-center justify-content-sm-end col-lg-6 col-md-6 col-sm-6 col-12"
              >
                <div class="social-icon floatright">
                  <ul>
                    <li>
                      <a
                        class="twitter"
                        target="_blank"
                        href="https://twitter.com/YourPersonalPAC"
                      >
                        <i class="fa fa-twitter-square" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        class="facebook"
                        target="_blank"
                        href="https://www.facebook.com/ThePersonalPACProject"
                      >
                        <i class="fa fa-facebook-square" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        class="youtube"
                        target="_blank"
                        href="https://twitter.com/YourPersonalPAC"
                      >
                        <i class="fa fa-youtube-square" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-middle ptb-40">
          <div class="container-lg">
            <div class="row">
              <div class="col-lg-3 col-md-6">
                <div class="middle-text">
                  <div class="social-logo">
                    <a href="/">
                      <img
                        src="../assets/logo/giveproudly-logo-white.png"
                        alt=""
                        class="logo-size"
                      />
                    </a>
                  </div>
                  <div class="middle-mgn"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bottom">
          <div class="container-lg">
            <div class="footer-bottom-all">
              <div class="row">
                <div
                  class="d-flex flex-row align-items-center justify-content-center justify-content-sm-start col-12 col-sm-7 col-lg-6"
                >
                  <div class="footer-menu">
                    <ul>
                      <li><a href="/" class="remove-link">HOME</a></li>
                      <li><a href="/about" class="remove-link">ABOUT</a></li>
                      <li><a href="/faq" class="remove-link">FAQ</a></li>
                      <li>
                        <a href="/contact" class="remove-link">CONTACT</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="d-flex flex-row align-items-center justify-content-center justify-content-sm-end col-12 col-sm-5 col-lg-6"
                >
                  <div class="footer-menu right-side-menu">
                    <ul>
                      <li>
                        <a href="/privacy" class="remove-link">PRIVACY</a>
                      </li>
                      <li>
                        <a href="/terms" class="active remove-link">T&amp;C</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>
<!-- jquery -->
<script src="assets/js/vendor/jquery-1.12.4.min.js"></script>
<!-- Popper JS -->
<script src="js/popper.js"></script>
<!-- bootstrap JS -->
<script src="js/bootstrap.min.js"></script>
<!-- wow JS -->
<script src="js/wow.min.js"></script>
<!-- plugins JS -->
<script src="js/plugins.js"></script>
<!-- main JS -->
<script src="js/main.js"></script>
<!-- custom JS -->
<script>
import "@/assets/styles/give-proudly/style.css";

// import "@/assets/styles/style.css";
import "@/assets/styles/animate.css";
import "@/assets/styles/bootstrap.min.css";
// import "@/assets/styles/chosen.min.css";
import "@/assets/styles/font-awesome.min.css";
import "@/assets/styles/magnific-popup.css";
import "@/assets/styles/main.css";
import "@/assets/styles/meanmenu.min.css";
import "@/assets/styles/normalize.css";
import "@/assets/styles/responsive.css";
// import "@/assets/styles/style-customizer.css";
// import "@/assets/styles/color-custom.css";
import "@/assets/styles/give-proudly/color-custom.css";

export default {
  data() {
    return {};
  },
};
function createNode(element) {
  return document.createElement(element);
}

function append(parent, el) {
  return parent.appendChild(el);
}

// const termsTextEl = document.getElementById("termsText");
const termsTextLink = `${process.env.VUE_APP_API_URL}/content/terms`;

fetch(termsTextLink)
  .then((resp) => resp.json())
  .then(function (data) {
    if (data.code == 200 && data.message == "success") {
      let result = data.content;
      let tmpDiv = createNode("div");

      tmpDiv.innerHTML = result.terms;
      append(document.getElementById("termsText"), tmpDiv);
    }
  });
</script>
<style scoped>
.logo-size {
  object-fit: cover;
  height: 200px;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}
.remove-link {
  text-decoration: none;
  color: white;
  border: none !important;
}
.mobile-menu {
  height: 60px;
}
.active {
  color: black;
  text-decoration: underline;
}
</style>
